import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import "../../../../src/styles/global.scss"
import RightArrow from "../../SliderArrows/RightArrows"
import useWindowSize from "../../../../hooks/useWindowSize"
import CommonLink from "../../CommonLink"

const MoreRoomSlider = ({ data }) => {
  const { width } = useWindowSize()
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: data.length > 3 ? 3 : data.length - 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      {width > 600 ? (
        <Slider {...settings} nextArrow={<RightArrow />}>
          {data.map((item, index) => (
            <div
              className="more_sider_items px-3"
              key={index}
              data-aos="fade-up"
              data-aos-delay={`${100 + index * 100}`}
              data-aos-duration={`${1100 + index * 100}`}
            >
              <div className="more_thumb">
                <img src={item?.thumbnail?.asset?.url} alt={item?.name} />
              </div>
              <div className="more_text">
                <div className="rooms_text">
                  <h4>{item?.name}</h4>
                  <span>{item?.features}</span>
                  <CommonLink
                    link={`/stay/${item?.id}`}
                    type="internal"
                  >
                    VIEW DETAILS&nbsp;
                    <i className="fas fa-angle-right"></i>
                  </CommonLink>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <>
          {data.map((item, index) => (
            <>
              {index < 3 && (
                <div
                  className="more_sider_items px-3 mb-4"
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={`${100 + index * 50}`}
                  data-aos-duration={`${1100 + index * 100}`}
                >
                  <div className="more_thumb">
                    <img src={item?.thumbnail?.asset?.url} alt={item?.name} />
                  </div>
                  <div className="more_text">
                    <div className="rooms_text more__room">
                      <div>
                        <h4>{item?.name}</h4>
                        <span>{item?.features}</span>
                      </div>
                      <div>
                        <CommonLink
                          link={`/stay/${item?.id}`}
                          type="internal"
                        >
                          VIEW DETAILS&nbsp;
                          <i className="fas fa-angle-right"></i>
                        </CommonLink>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
          <div className="single__room__see mt-5">
            <CommonLink link={"/stay"} type="internal">
              See All
            </CommonLink>
          </div>
        </>
      )}
    </>
  )
}
export default MoreRoomSlider
