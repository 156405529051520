import React from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import DeluxRoomSlider from "../Sliders/DeluxRoomSlider"
import CommonLink from "../CommonLink"

const StaySingleRoom = ({
  singleRoomImage,
  description,
  rate,
  includeTitle,
  title,
  roomIncludes,
  roomFeature,
  bookButton,
  moreRoomsImages,
  pageClass,
}) => {
  const { width } = useWindowSize()
  return (
    <div className={`deluxe__section ${pageClass}`}>
      <div className="container">
        {width > 600 && (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="deluxe_blk">
                  <div
                    className="deluxe_title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="800"
                  >
                    <h3>{title}</h3>
                  </div>
                  <div
                    className="deluxe_site_blk"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="900"
                  >
                    <a href="#">{rate}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="deluxe_tr"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <a href="#">{roomFeature}</a>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          {width < 768 ? (
            <>
              <DeluxRoomSlider data={moreRoomsImages} />
              <div className="row">
                <div className="col-lg-12">
                  <div className="deluxe_blk">
                    <div
                      className="deluxe_title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                      data-aos-duration="800"
                    >
                      <h3>{title}</h3>
                    </div>
                    <div
                      className="deluxe_site_blk"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="900"
                    >
                      <a href="#">{rate}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="deluxe_tr"
                    data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-duration="1000"
                  >
                    <a href="#">{roomFeature}</a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="col-lg-6">
              <div
                className="deluxe_site_thumb"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1200"
              >
                <img src={singleRoomImage?.asset?.url} alt="" />
                <CommonLink link={bookButton?.path} type={bookButton?.type}>
                  {bookButton?.label}
                </CommonLink>
              </div>
            </div>
          )}
          <div className="col-lg-6">
            <div
              className="deluxe_site_content"
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-duration="1300"
            >
              <p>{description}</p>
              <h4>{includeTitle} </h4>
            </div>
            <div
              className="deluxe_site_list"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1400"
            >
              <ul>
                {roomIncludes.map((item, index) => (
                  <li>
                    <a href="#">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {width <= 600 && (
            <div
              className="deluxe_site_thumb mt-5"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <CommonLink link={bookButton?.path} type={bookButton?.type}>
                {bookButton?.label}
              </CommonLink>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StaySingleRoom
