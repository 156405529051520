import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import RightArrow from "../../SliderArrows/RightArrows"
import useWindowSize from "../../../../hooks/useWindowSize"

const DeluxRoomSlider = ({ data }) => {
  const { width } = useWindowSize()
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerPadding: "20px",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 2000,
          swipeToSlide: true,
          centerMode: true,
          centerPadding: "20px",
          pauseOnHover: true,
        },
      },
    ],
  }
  return (
    <div className="deluxe__slider__serction stay">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="deluxe_slider_active owl-carousel"
              style={
                width < 601
                  ? { marginLeft: "-0.25rem" }
                  : { marginLeft: "-1rem" }
              }
            >
              {data.length >= 3 || width < 768 ? (
                <Slider
                  {...settings}
                  nextArrow={width < 601 ? null : <RightArrow />}
                >
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={
                        width < 601
                          ? "deluxe_slider_items px-1"
                          : "deluxe_slider_items px-3"
                      }
                      data-aos="fade-up"
                      data-aos-delay={`${100 + index * 50}`}
                      data-aos-duration={`${1100 + index * 100}`}
                    >
                      <img
                        className="deluxe__slider"
                        src={item?.asset?.url}
                        alt="..."
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div
                  style={{
                    display: "flex",
                    padding: "0 10px",
                  }}
                >
                  {data.map((item, index) => (
                    <div class="px-2" key={index}>
                      <img
                        src={item?.asset?.url}
                        alt="..."
                        style={{ borderRadius: "10px", height: "300px" }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeluxRoomSlider
