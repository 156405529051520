import React, { useEffect } from "react"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import StaySingleRoom from "../../components/StaySingleRoom"
import Aos from "aos"
import DeluxRoomSlider from "../../components/Sliders/DeluxRoomSlider"
import MoreRoomSlider from "../../components/Sliders/MoreRoomSlider"
import { graphql } from "gatsby"
import useWindowSize from "../../../hooks/useWindowSize"
import BookingOnline from "../../components/BookingOnline"
import BackToTop from "../../components/BackToTop"
import StayBookingOnline from "../../components/StayBooking"
import Seo from "../../components/Seo"
const SingleRoom = ({ pageContext: { id }, data }) => {
  const { width } = useWindowSize()
  useEffect(() => {
    Aos.init()
  })
  const {
    name,
    description,
    rate,
    features,
    roomIncludes,
    includeTitle,
    bookLink,
    thumbnail,
    singleImage,
    images,
  } = data.sanityRooms
  return (
    <>
      <Seo
        title={name}
        description={
          "User can see selected room deescription with more images and feature available."
        }
      />
      <Layout>
        {width > 767 && (
          <div className="header_bottom">
            <div className="container">
              <StayBookingOnline />
            </div>
          </div>
        )}
        <HeroBanner
          bannerImg={thumbnail?.asset?.gatsbyImageData}
          pageClass={"single__room__page"}
        />
        {width <= 767 && (
          <div className="action__section">
            <div className="container">
              <BookingOnline />
            </div>
          </div>
        )}
        <BackToTop />
        <StaySingleRoom
          singleRoomImage={singleImage}
          description={description}
          rate={rate}
          includeTitle={includeTitle}
          title={name}
          roomIncludes={roomIncludes}
          roomFeature={features}
          bookButton={bookLink}
          pageClass={"single__room"}
          moreRoomsImages={images}
        />
        {width > 767 && (
          <div className="more__image__slider">
            <DeluxRoomSlider data={images} />
          </div>
        )}
        <div className="more__slider__section stay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="more_title">
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1500"
                  >
                    More Rooms
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="more_slider_active owl-carousel"
                  style={{ marginLeft: "-1.25rem" }}
                >
                  <MoreRoomSlider
                    data={data.allSanityRooms.nodes.filter(function (item) {
                      if (item?.id !== id) {
                        return item
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default SingleRoom
export const singleRooms = graphql`
  query SingleRoomQuery($id: String) {
    sanityRooms(id: { eq: $id }) {
      name
      rate
      roomIncludes
      includeTitle
      features
      description
      bookLink {
        label
        path
        type
      }
      categories {
        name
      }
      singleImage {
        asset {
          url
        }
      }
      thumbnail {
        asset {
          gatsbyImageData(fit: FILLMAX, formats: WEBP)
        }
      }
      images {
        asset {
          url
        }
      }
    }
    allSanityRooms {
      nodes {
        id
        name
        features
        thumbnail {
          asset {
            url
          }
        }
      }
    }
  }
`
