import React from "react"
const RightArrow = props => {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="29"
        viewBox="0 0 17 29"
        fill="none"
      >
        <path d="M1 1L15 14.5L1 28" stroke="#002643" stroke-width="2" />
      </svg>
    </div>
  )
}

export default RightArrow
